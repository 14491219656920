<template>
  <v-dialog
    no-click-animation
    hide-overlay
    :overlay-opacity="0"
    transition="fade-transition"
    fullscreen
    persistent
    :value="isOpen ? true : false"
  >
    <v-card class="installation-card d-flex align-center mb-16">
      <div class="mx-auto align-center px-8 d-flex flex-column mt-8">
        <div class="d-flex justify-center">
          <v-img
          v-if="isLogoBase64 || !customLogo"
            :width="$vuetify.breakpoint.mobile ? 171 : 200"
            :height="$vuetify.breakpoint.mobile ? 36 : 44"
            :src="customLogo ? customLogo : require(`@/assets/logo${isDark ? '-white' : ''}.svg`)"
            contain
            position="center center"
          />
          <div v-else v-html="customLogo" class="mb-10 d-flex" 
               
            >
            </div>
        </div>
        <div class="d-flex justify-center pt-16">
          <h1 class="text--center d-flex flex-column align-center">
            <span class="text-center primary--text">{{ $t('heading.onboarding.import.success.title') }}</span>
            <span class="text-center">
              {{ $t('heading.onboarding.import.success.subtitle') }}
            </span>
          </h1>
        </div>

        <div class="text-center">
          <p class="p-1 heading--text my-8">
            {{ $t('heading.onboarding.import.success.info.general') }}
          </p>
        </div>

        <div class="success-box mb-5">
          <span class="font-weight-600"> </span>
          <i18next :translation="$t('heading.onboarding.import.success.info.domain')">
            <template #domain>
              <br/>
              <a
                :href="importedInstance.url"
                class="h5 font-weight-600"
                target="_blank"
              >
                {{ importedInstance.url }}
              </a>
            </template>
          </i18next>
        </div>

        <div class="d-flex justify-center my-8">
          <v-btn color="primary" x-large elevation="0" @click="$emit('next')">
            <span class="font-weight-600">{{ $t('button.returnToDashboard') }}</span>
          </v-btn>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    isOpen: Boolean,
    importedInstance: Object,
    nameservers: Array,
  },
  data: function () {
    return {
      copiedText: "",
      copySetTimeout: null,
    };
  },
  methods: {
    copyToClipboard(text) {
      navigator.clipboard.writeText(text);
      this.copiedText = text;
    },
    resetData() {},
  },
  watch: {
    copiedText: function (value) {
      this.copySetTimeout && clearTimeout(this.copySetTimeout);

      if (value) {
        setTimeout(() => {
          this.copiedText = "";
        }, 10000);
      }
    },
    isOpen: function (value) {
      this.resetData();

      if (value) {
        this.$nextTick(() => {
          this.$store.dispatch("lockBodyScroll");
          setTimeout(() => {
            document.querySelector("html").classList.add("overflow-y-hidden");
          }, 300);
        });
      } else {
        this.$store.dispatch("unlockBodyScroll");
        document.querySelector("html").classList.remove("overflow-y-hidden");
      }
    },
  },
  computed: {
    isDark() {
      return this.$vuetify.theme.dark;
    },
    isDemo() {
      return this.$store.state.home.user.email == "demo@panelalpha.com";
    },
    customLogo: function () {
      if(this.$vuetify.theme.dark && this.$store.state.ThemeModule.darkLogo) {
        return this.$store.state.ThemeModule.darkLogo
      }

      if(!this.$vuetify.theme.dark && this.$store.state.ThemeModule.lightLogo) {
        return this.$store.state.ThemeModule.lightLogo
      }

      return false
    },
    isLogoBase64: function() {
      return this.customLogo &&  this.customLogo.trim().startsWith('data:image/');
    }
  },
};
</script>

<style lang="scss" scoped>
.v-dialog {
  overflow: auto !important;
}

.v-text-field--outlined::v-deep {
  > .v-input__control > .v-input__slot {
    background-color: #fff;
  }
}

.installation-card {
  max-height: 100vw;
  max-width: 100vw;
  display: flex;
  align-items: center;
  background: var(--v-body-base) !important;

  > div {
    max-width: 684px;
    width: 100%;
  }

  .success-box {
    width: 100%;
    background: var(--v-primary-lighten4);
    color: var(--v-primary-base);
    padding: 16px;
    text-align: center;
    border-radius: 8px;
  }

  .form-box {
    border: 1px solid var(--v-gray-lighten4);
    border-radius: 8px;
    padding: 16px;
  }
}

h1 {
  font-size: $font-size-h1;
  line-height: $line-height-h1;
}
.p-1 {
  font-size: $font-size-xlg;
  line-height: $line-height-xlg;
  font-weight: $font-weight-extra-light;
}

.v-btn.v-size--x-large {
  height: 60px;
  padding: 16px;
}
</style>
