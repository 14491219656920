<template>
  <v-dialog
    hide-overlay
    :overlay-opacity="0"
    transition="fade-transition"
    fullscreen
    v-model="dialogOpen"
    no-click-animation
    refs="dialog"
  >
    <v-card>
      <div class="" :class="$vuetify.breakpoint.mobile ? 'px-6' : 'px-16'">
        <v-container fluid class="px-0">
          <v-row
            class="pt-8 d-flex justify-center flex-column align-center top-info"
          >
            <v-img
              v-if="isLogoBase64 || !customLogo"
              :width="$vuetify.breakpoint.mobile ? 171 : 200"
              :height="$vuetify.breakpoint.mobile ? 36 : 44"
              :src="customLogo ? customLogo : require(`@/assets/logo${isDark ? '-white' : ''}.svg`)"
              contain
              position="center center"
            />
            <div v-else v-html="customLogo" class="mb-10 d-flex" 
            >
            </div>
            <h1 class="pt-8">{{ $t('heading.onboarding.chooseTheme.title') }}</h1>
            {{ $t('heading.onboarding.chooseTheme.subtitle') }}
          </v-row>
          <v-row class="" no-gutters>
            <tags-sidebar
              @goBack="$emit('goBack')"
              v-if="!loadingThemeCategories"
              :groups="themeCategories"
              :group.sync="selectedThemeCategory"
              :allThemesCount="allThemesCount"
              :default-theme-categories="defaultThemeCategories"
              :recommendedThemes="recommendedThemes"
            />
            <tags-sidebar-skeleton v-else />

            <v-col
              :lg="10"
              :md="9"
              :sm="12"
              :class="[
                $vuetify.breakpoint.lgAndDown ? '' : 'pl-12',

                $vuetify.breakpoint.mobile ? 'pt-0' : 'pl-6',
              ]"
              style="position: relative"
            >
              <v-row
                :class="$vuetify.breakpoint.mobile ? 'mt-0 mx-4' : 'mt-8 mx-4'"
                class="d-flex align-center justify-space-between mb-4 sticky-filters"
              >
                <div
                  class="d-flex w-100"
                  :class="$vuetify.breakpoint.smAndDown ? 'flex-column' : ''"
                >
                  <v-btn
                    v-if="$vuetify.breakpoint.mobile"
                    :class="$vuetify.breakpoint.smAndDown ? 'mb-5' : ''"
                    :block="$vuetify.breakpoint.smAndDown ? true : false"
                    elevation="0"
                    class="mt-0 pt-0"
                    id="#switch"
                    ref="switch"
                    outlined
                    small
                    color="gray"
                    @click="$emit('goBack')"
                    style="max-width: 300px !important"
                  >
                    <v-icon size="16px" color="gray--darken-1">
                      $arrowleft
                    </v-icon>
                    {{ $t('button.dashboard.back') }}
                  </v-btn>
                  <div class="d-flex ml-auto align-center">
                    <data-iterator-filter-controls
                      :sortBy="sortBy"
                      :sortDesc="sortDesc"
                      :keys="keys"
                      :showSearchTerm="true"
                      :searchTerm="searchTerm"
                      :fullWidthSearch="false"
                      @update:sortBy="changeSortBy"
                      @update:sortDesc="handleSortDescChange"
                      @update:searchTerm="handleSearchTermChange"
                    />
                  </div>
                </div>
              </v-row>

              <v-data-iterator
                disable-pagination
                class="d-flex mx-4 flex-wrap flex-row"
                :items="themes"
                hide-default-footer
                :sort-by="sortBy.value"
                :sort-desc="sortDesc"
                :search="searchTerm"
                :loading="loadingThemes"
              >
                <template v-slot:loading>
                  <div class="d-flex flex-wrap w-100">
                    <v-col
                      :xl="3"
                      :lg="4"
                      :md="6"
                      :sm="6"
                      :xs="12"
                      v-for="item in 8"
                      :key="item"
                      class="px-4 py-4"
                    >
                      <TemplateCardSkeleton />
                    </v-col>
                  </div>
                </template>
                <template v-slot:default="{ items }">
                  <v-row class="d-flex w-100 flex-wrap" v-if="loadingThemes">
                    <v-col
                      :xl="3"
                      :lg="4"
                      :md="6"
                      :sm="6"
                      :xs="12"
                      v-for="item in 8"
                      :key="item"
                      class="px-4 py-4"
                    >
                      <TemplateCardSkeleton />
                    </v-col>
                  </v-row>
                  <v-row class="d-flex flex-wrap" v-else>
                    <v-col
                      :xl="3"
                      :lg="4"
                      :md="6"
                      :sm="6"
                      :xs="12"
                      v-for="(item, index) in items"
                      :key="`${item.slug}-${index}`"
                      class="py-4 px-4"
                    >
                      <TemplateCard :item="item" @selectTheme="selectTheme" />
                    </v-col>
                    <div
                      style="height: 92px; width: 100%"
                      class="d-flex align-center justify-center flex-column"
                      v-if="
                        !loadingThemes && !allThemesLoaded && themes.length > 0
                      "
                    >
                      <v-progress-circular
                        color="primary"
                        size="24"
                        indeterminate
                        width="4"
                      >
                      </v-progress-circular>
                      <span class="primary--text p-4 mt-3">{{ $t('message.loading') }}</span>
                    </div>
                  </v-row>
                </template>
                <template v-slot:no-data>
                  <template v-if="searchTerm">
                    <data-iterator-no-results-container
                      @clearAllFilters="clearFilters"
                      :searchTerm="searchTerm"
                    />
                  </template>
                  <template v-else>
                    <div
                      class="no-data-container d-flex flex-column align-center justify-center mt-10 mr-20"
                    >
                      <theme-no-data-img />
                      <h4 class="mb-4">{{ $t('message.emptyTable.chooseTheme.title') }}</h4>
                      <p class="p-1">
                        {{ $t('message.emptyTable.chooseTheme.subtitle') }}
                      </p>
                    </div>
                  </template>
                </template>
              </v-data-iterator>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </v-card>
    <template v-if="!loadingThemes && !allThemesLoaded && themes.length > 0">
      <div v-intersect.quiet="infiniteScrolling"></div>
    </template>
  </v-dialog>
</template>

<script>
import Api from "@/apis/Api";

import TagsSidebar from "./TagsSidebar.vue";
import TagsSidebarSkeleton from "./TagsSidebarSkeleton.vue";
import TemplateCard from "./TemplateCard.vue";
import TemplateCardSkeleton from "./TemplateCardSkeleton.vue";
import DataIteratorFilterControls from "../dataIterator/DataIteratorFilterControls.vue";
import DataIteratorNoResultsContainer from "../dataIterator/DataIteratorNoResultsContainer.vue";
import ThemeNoDataImg from "../illustrations/theme-no-data-img.vue";

import DataIteratorPageMixin from "../../mixins/DataIteratorPageMixin";

export default {
  components: {
    TagsSidebar,
    TagsSidebarSkeleton,
    TemplateCard,
    TemplateCardSkeleton,
    DataIteratorFilterControls,
    DataIteratorNoResultsContainer,
    ThemeNoDataImg,
  },
  mixins: [DataIteratorPageMixin],
  props: {
    isOpen: Boolean,
    selectedService: Object,
  },
  data() {
    return {
      loadingThemeCategories: false,
      loadingThemes: false,
      allThemesLoaded: false,
      allThemesCount: 0,
      themes: [],
      ssoUrl: "",
      page: 1,
      perPage: 12,
      sortBy: {
        text: 'sort.popular',
        value: "popular",
        sortable: true,
      },
      sortDesc: false,
      keys: [
        {
          text: 'sort.popular',
          value: "popular",
          sortable: true,
        },
        {
          text: 'sort.featured',
          value: "featured",
          sortable: true,
        },
        {
          text: 'sort.recentlyUpdated',
          value: "updated",
          sortable: true,
        },
        {
          text: 'sort.latest',
          value: "new",
          sortable: true,
        },
      ],
      serverAccounts: [],
      selectedProduct: {},
      themeCategories: [],
      selectedThemeCategory: {
        text: 'popular',
        value: "popular",
        type: "default",
      },
      defaultThemeCategories: [
        {
          text: 'popular',
          value: "popular",
          type: "default",
        },
        {
          text: 'latest',
          value: "new",
          type: "default",
        },
      ],
      recommendedThemes: false,
    };
  },
  computed: {
    dialogOpen: {
      get() {
        if (this.isOpen && this.selectedService.plan_default_theme) {
          this.$emit("theme-selected", {
            slug: this.selectedService.plan_default_theme,
          });
        }
        return this.isOpen;
      },
      set(value) {
        this.$emit("goBack", value);
      },
    },
    isDark() {
      return this.$vuetify.theme.dark;
    },
    customLogo: function () {
      if(this.$vuetify.theme.dark && this.$store.state.ThemeModule.darkLogo) {
        return this.$store.state.ThemeModule.darkLogo
      }

      if(!this.$vuetify.theme.dark && this.$store.state.ThemeModule.lightLogo) {
        return this.$store.state.ThemeModule.lightLogo
      }

      return false
    },
    isLogoBase64: function() {
      return this.customLogo &&  this.customLogo.trim().startsWith('data:image/');
    }
  },
  watch: {
    isOpen(value) {
      if (value) {
        this.loadThemeCategories();
        if (this.themes.length === 0) {
          this.loadDefaultThemes();
        }
        if (this.recommendedThemes.length === 0) {
          this.loadThemesAssignedToPlan();
        }
      }
    },
    page() {
      // this.allThemesLoaded = false;
      // this.allThemesCount = 0;

      if (this.selectedThemeCategory.type === "default") {
        this.nextDefaultThemesPage();
      } else if (this.selectedThemeCategory.type === "recommended") {
        this.nextThemesAssignedToPlan();
      } else {
        this.nextThemesAssignedToCategory();
      }
    },
    searchTerm() {
      // this.allThemesCount = 0;
      // this.allThemesLoaded = false;

      if (this.selectedThemeCategory.type === "default") {
        this.loadDefaultThemes();
      } else if (this.selectedThemeCategory.type === "recommended") {
        this.loadThemesAssignedToPlan();
      } else {
        this.loadThemesAssignedToCategory();
      }
    },
    selectedThemeCategory() {
      // this.allThemesCount = 0;
      // this.allThemesLoaded = false;

      if (this.selectedThemeCategory.type === "default") {
        this.loadDefaultThemes();
      } else if (this.selectedThemeCategory.type === "recommended") {
        this.loadThemesAssignedToPlan();
      } else {
        this.loadThemesAssignedToCategory();
      }
    },
  },
  methods: {
    infiniteScrolling() {
      this.$nextTick(() => {
        this.page = this.page + 1;
      });
    },
    selectTheme: function (theme) {
      this.$emit("theme-selected", theme);
    },
    clearFilters: function () {
      this.searchTerm = "";
    },
    loadThemeCategories() {
      this.loadingThemeCategories = true;
      Api.cached()
        .get("/instances/onboarding/theme-categories")
        .then((response) => {
          this.themeCategories = this.addDividersToThemeCategories(
            response.data.data
          );
        })
        .finally(() => {
          this.loadingThemeCategories = false;
        });
    },
    nextDefaultThemesPage() {
      let query = `page=${this.page}&per_page=${this.perPage}`;

      this.sortBy = this.keys.find(
        (item) => item.value === this.selectedThemeCategory.value
      );
      query += `&browse=${this.sortBy.value}`;

      if (this.searchTerm) {
        query += `&search_term=${this.searchTerm}`;
      }

      Api.cached()
        .get(`/instances/onboarding/themes?${query}`)
        .then((response) => {
          if (this.page !== 1) {
            this.themes = [...this.themes, ...response.data.data];
          } else {
            this.themes = response.data.data;
          }

          this.allThemesCount = response.data.meta.total;

          if (this.themes.length === this.allThemesCount) {
            this.allThemesLoaded = true;
          }
        })
        .finally(() => {
          this.loadingThemes = false;
        });
    },
    loadDefaultThemes() {
      this.loadingThemes = true;

      let query = `page=${this.page}&per_page=${this.perPage}`;
      this.sortBy = this.keys.find(
        (item) => item.value === this.selectedThemeCategory.value
      );

      query += `&browse=${this.sortBy.value}`;

      if (this.searchTerm) {
        query += `&search_term=${this.searchTerm}`;
      }

      Api.cached()
        .get(`/instances/onboarding/themes?${query}`)
        .then((response) => {
          this.themes = response.data.data;
        })
        .finally(() => {
          this.page = 1;
          this.loadingThemes = false;
        });
    },
    addDividersToThemeCategories(categories) {
      let maxOrder = 0;
      const array = [];

      categories.sort((a, b) => a.order - b.order);
      categories.forEach((item) => {
        if (item.order > maxOrder) {
          maxOrder = item.order;
        }
      });
      for (let i = 1; i <= maxOrder; i++) {
        const category = categories.find((item) => item.order === i);
        if (category) {
          array.push(category);
        } else {
          array.push({ type: "divider", order: i });
        }
      }
      return array;
    },
    loadThemesAssignedToCategory() {
      this.loadingThemes = true;

      const params = new URLSearchParams({
        page: 1,
        per_page: this.perPage,
        sort_by: "id",
        sort_dir: "asc",
      });

      if (this.search) {
        params.append("search_term", this.searchTerm);
      }

      Api.get(
        `/instances/onboarding/${
          this.selectedThemeCategory.id
        }/themes-from-category?${params.toString()}`
      )
        .then((response) => {
          this.themes = response.data.data;
        })
        .finally(() => {
          this.page = 1;
          this.loadingThemes = false;
        });
    },
    nextThemesAssignedToCategory() {
      const params = new URLSearchParams({
        page: this.page,
        per_page: this.perPage,
        sort_by: "id",
        sort_dir: "asc",
      });

      if (this.search) {
        params.append("search_term", this.searchTerm);
      }

      Api.get(
        `/instances/onboarding/${
          this.selectedThemeCategory.id
        }/themes-from-category?${params.toString()}`
      )
        .then((response) => {
          if (this.page !== 1) {
            this.themes = [...this.themes, ...response.data.data];
          } else {
            this.themes = response.data.data;
          }

          this.allThemesCount = response.data.meta.total;

          if (this.themes.length === this.allThemesCount) {
            this.allThemesLoaded = true;
          } else {
            this.allThemesLoaded = false;
          }
        })
        .finally(() => {
          this.loadingThemes = false;
        });
    },
    loadThemesAssignedToPlan() {
      this.loadingThemes = true;

      const params = new URLSearchParams({
        page: 1,
        per_page: this.perPage,
        sort_by: "id",
        sort_dir: "asc",
      });

      if (this.search) {
        params.append("search_term", this.searchTerm);
      }

      Api.get(`/instances/onboarding/recommended-themes?${params.toString()}`)
        .then((response) => {
          this.themes = response.data.data;
          if (response.data.data.length > 0) {
            this.recommendedThemes = true;
          } else {
            this.recommendedThemes = false;
          }
        })
        .finally(() => {
          this.page = 1;
          this.loadingThemes = false;
        });
    },
    nextThemesAssignedToPlan() {
      const params = new URLSearchParams({
        page: this.page,
        per_page: this.perPage,
        sort_by: "id",
        sort_dir: "asc",
      });

      if (this.search) {
        params.append("search_term", this.searchTerm);
      }

      Api.get(`/instances/onboarding/recommended-themes?${params.toString()}`)
        .then((response) => {
          if (this.page !== 1) {
            this.themes = [...this.themes, ...response.data.data];
          } else {
            this.themes = response.data.data;
          }

          this.allThemesCount = response.data.meta.total;

          if (this.themes.length === this.allThemesCount) {
            this.allThemesLoaded = true;
          } else {
            this.allThemesLoaded = false;
          }
        })
        .finally(() => {
          this.loadingThemes = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.v-card {
  background-color: var(--v-body-base) !important;
}
.close-model {
  position: absolute;
  top: 24px;
  right: 24px;
}
.v-dialog__content::v-deep {
  .v-dialog {
    overflow-y: auto;
    overflow-x: hidden;
    background: rgba(255, 255, 255, 1);
    @supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
      background: hsla(0, 0%, 100%, 0.24);
      -webkit-backdrop-filter: blur(20px);
      backdrop-filter: blur(30px);
    }
  }
}
.v-data-iterator::v-deep {
  > div {
    width: 100%;
  }
}

.sticky-filters {
  position: sticky;
  top: 24px;
  z-index: 900;
  &::before {
    position: absolute;
    content: "";
    width: calc(100% + 80px);
    height: calc(100% + 40px);
    left: -40px;
    top: -24px;
  }
}

.top-info {
  position: relative;
  z-index: 1011;
  &::-webkit-scrollbar {
    display: none;
  }
}

.no-data-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  padding: 40px;
  border: 1px dashed map-get($gray, lighten2);
  border-radius: 8px;
  margin: 16px 0 32px 0;
}
</style>
