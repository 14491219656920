<template>
  <v-navigation-drawer
    v-model="open"
    class="style-drawer"
    :class="[open ? 'style-drawer--open' : '']"
    fixed
    temporary
    right
    hide-overlay
    :width="280"
  >
    <div class="style-drawer__scroll">
      <div class="style-drawer__content d-flex flex-column justify-start pb-6">
        <h4 class="font-weight-light">
          {{ $t("heading.styleManager.title") }}
        </h4>
        <div class="style-drawer__items-container">
          <div class="style-drawer__item mb-6">
            <h5 class="p-2 mb-4 mt-6 font-weight-bold">
              {{ $t("heading.styleManager.darkMode.title") }}
            </h5>
            <div class="d-flex flex-column">
              <div class="d-flex align-center">
                <v-checkbox
                  class="v-input--switch mt-0 pt-0 mr-3 primary--text"
                  :input-value="selectedStyle.darkMode"
                  ref="darkModeSwitcher"
                >
                  <template v-slot:append>
                    <div class="v-input__control">
                      <div class="v-input__slot">
                        <div
                          class="v-input--selection-controls__input"
                          @click="
                            changeDarkModeSelection(!selectedStyle.darkMode)
                          "
                        >
                          <input role="switch" />
                          <div class="v-input--switch__track"></div>
                          <div class="v-input--switch__thumb">
                            <v-icon
                              v-show="!selectedStyle.darkMode"
                              size="13"
                              color="primary"
                              >$sun</v-icon
                            >
                            <v-icon
                              v-show="selectedStyle.darkMode"
                              size="13"
                              color="primary"
                              >$moon</v-icon
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>
                </v-checkbox>
                <p class="p-3 mb-0">
                  {{ $t("heading.styleManager.darkMode.description") }}
                </p>
              </div>
            </div>
          </div>
          <div class="style-drawer__item mb-6">
            <h5 class="p-2 mb-4 mt-6 font-weight-bold">
              {{ $t("heading.styleManager.logo.title") }}
            </h5>
            <style-manager-change-logo
              :lightLogo="customLightLogo"
              :darkLogo="customDarkLogo"
              @update:lightLogoData="changeLightLogo"
              @update:darkLogoData="changeDarkLogo"
            />
          </div>
          <div class="style-drawer__item mb-6">
            <h5 class="p-2 mb-4 mt-6 font-weight-bold">
              {{ $t("heading.styleManager.favicon.title") }}
            </h5>
            <style-manager-change-favicon
              :faviconData="customFavicon"
              @update:file="changeSelectedFavicon"
              @update:faviconData="changeFaviconData"
            />
          </div>
          <div class="style-drawer__item mb-6">
            <h5 class="p-2 mb-4 mt-6 font-weight-bold">
              {{ $t("heading.styleManager.colorScheme.title") }}
            </h5>
            <style-manager-color-group-picker
              :selectedColors="selectedColors"
              @update:selectedColors="changeSelectedColorsTheme"
            />
          </div>
          <div class="style-drawer__item mb-6">
            <h5 class="p-2 mb-4 mt-6 font-weight-bold">
              {{ $t("heading.styleManager.primaryColor.title") }}
            </h5>
            <style-manager-group-editor
              :selectedColors="selectedColors"
              @update:selectedColors="changeSelectedColorsTheme"
            />
          </div>
          <div
            class="style-drawer__item mb-6"
            v-if="Object.keys(this.selectedStyle).length"
          >
            <h5 class="p-2 mb-4 mt-6 font-weight-bold">
              {{ $t("heading.styleManager.additionalSettings.title") }}
            </h5>
            <div class="d-flex flex-column">
              <div class="d-flex align-center">
                <v-switch
                  @change="changeShadowStyleSelection"
                  class="my-0 py-0 mr-4"
                  hide-details
                  v-model="this.selectedStyle.disableShadows"
                />
                <p class="p-3 mb-0">
                  {{
                    $t(
                      "heading.styleManager.additionalSettings.disableShadows.title"
                    )
                  }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex flex-column mt-auto">
          <v-btn
            color="primary"
            large
            elevation="0"
            class="mb-2"
            @click="saveColorsTheme"
            >{{ $t("button.saveChanges") }}</v-btn
          >

          <v-btn
            color="primary--text text--darken-1"
            style="background-color: var(--v-primary-lighten4)"
            large
            elevation="0"
            @click="restoreDefault"
            >{{ $t("button.restoreDefault") }}</v-btn
          >
        </div>
      </div>
    </div>

    <div
      class="style-drawer__activator"
      v-ripple="'primary'"
      @click="open = !open"
    >
      <div class="style-drawer__activator-content">
        <div class="style-drawer__activator-icon">
          <v-icon size="24" color="primary">$sliders</v-icon>
        </div>
        <h6 class="p-5 style-drawer__activator-title">
          {{ $t("button.customize") }}
        </h6>
      </div>
    </div>
  </v-navigation-drawer>
</template>

<script>
import StyleManagerColorGroupPicker from "./StyleManagerColorGroupPicker.vue";
import StyleManagerGroupEditor from "./StyleManagerGroupEditor.vue";
import StyleManagerChangeLogo from "./StyleManagerChangeLogo.vue";
import Api from "@/apis/Api";
import StyleManagerChangeFavicon from "./StyleManagerChangeFavicon.vue";

export default {
  components: {
    StyleManagerColorGroupPicker,
    StyleManagerGroupEditor,
    StyleManagerChangeLogo,
    StyleManagerChangeFavicon,
  },
  data: function () {
    window.sm = this;
    return {
      lightLogoData: this.$store.state.ThemeModule.lightLogo,
      darkLogoData: this.$store.state.ThemeModule.darkLogo,
      faviconData: this.$store.state.ThemeModule.favicon,
      selectedColors: this.$store.state.ThemeModule.colors.primary,
      selectedStyle: this.$store.state.ThemeModule.style,
      open: false,
    };
  },
  computed: {
    isDark() {
      return this.$vuetify.theme.dark;
    },
    customLightLogo: function () {
      if (!this.$store.state.ThemeModule.lightLogo) {
        return false;
      }

      return this.$store.state.ThemeModule.lightLogo;
    },
    customDarkLogo: function () {
      if (!this.$store.state.ThemeModule.darkLogo) {
        return false;
      }

      return this.$store.state.ThemeModule.darkLogo;
    },
    customFavicon: function () {
      if (!this.$store.state.ThemeModule.favicon) {
        return false;
      }

      return this.$store.state.ThemeModule.favicon;
    },
  },
  watch: {
    "$route.name": function (name) {
      if (process.env.VUE_APP_DEMO_MODE && name == "Login") {
        this.open = true;
      }
    },
    "$route.query": function (query) {
      if (query.branding) {
        this.open = true;
      }
    },
    open: function () {
      this.selectedColors = this.$store.state.ThemeModule.colors.primary;
    },
    isDark(newVal) {
      localStorage.setItem('isDarkMode', newVal ? 'true' : 'false');
    }
  },
  methods: {
    changeDarkLogo: function (data) {
      this.darkLogoData = data;
      this.changeColorsTheme();
    },
    changeLightLogo: function (data) {
      this.lightLogoData = data;
      this.changeColorsTheme();
    },
    changeSelectedFavicon: function (data) {
      this.favicon = data;
      this.changeColorsTheme();
    },
    changeSelectedColorsTheme: function (theme) {
      this.selectedColors = theme;
      this.changeColorsTheme();
    },
    changeColorsTheme: function () {
      document.body.classList.add("no-transition");

      const isDarkMode = localStorage.getItem("isDarkMode") === "true";  
      const iframe = document.querySelector("iframe");

      if (iframe) {
          const iframeDoc = iframe.contentDocument || iframe.contentWindow.document;
          if (iframeDoc) {
              const fmDiv = iframeDoc.getElementById("fm");
              fmDiv.classList.add("no-transition");  
              if (fmDiv) {
                  if (isDarkMode) {
                      fmDiv.classList.add("is-dark");  
                      setTimeout(() => {
                        fmDiv.classList.remove("no-transition");
                      }, 100);
                  } else {
                      fmDiv.classList.remove("is-dark");  
                      setTimeout(() => {
                        fmDiv.classList.remove("no-transition");
                      }, 100);
                  }
              }
          }
      }

      this.$nextTick(() => {
        document.body.classList.remove("is-dark", "is-light");
        document.body.classList.add(
          this.selectedStyle.darkMode ? "is-dark" : "is-light"
        );

        setTimeout(() => {
          document.body.classList.remove("no-transition"); 
        }, 100);
      });

      this.$store.commit("changeColorsTheme", {
        colors: this.selectedColors,
        style: this.selectedStyle,
        lightLogo: this.lightLogoData,
        darkLogo: this.darkLogoData,
        favicon: this.faviconData,
      });
    },
    changeShadowStyleSelection: function () {
      this.selectedStyle.disableShadows = !this.selectedStyle.disableShadows;
      // if (this.$store.state.demoMode) {
      this.changeColorsTheme();
      // }
    },
    changeFaviconData: function (data) {
      this.faviconData = data;
      this.changeColorsTheme();
    },
    changeDarkModeSelection: function (value) {
      this.$refs.darkModeSwitcher.$refs.input.click();
      this.selectedStyle.darkMode = value;
      this.$vuetify.theme.dark = value;

      this.changeColorsTheme();
    },
    restoreDefault: function () {
      this.selectedColors = {
        base: "#07C07E",
        lighten1: "#1FD895",
        lighten2: "#CDF2E5",
        lighten4: "#E6F9F2",
        darken1: "#0AAA71",
      };
      this.selectedStyle = {
        disableShadows: false,
        darkMode: false, 
      };
      this.lightLogoData = false;
      this.darkLogoData = false;
      this.faviconData = false;
      
      document.body.classList.remove("is-dark", "is-light");
      document.body.classList.add("is-light");

      this.changeColorsTheme();
      this.$vuetify.theme.dark = false;
      this.saveColorsTheme();

      const iframe = document.querySelector("iframe");
      const iframeDoc = iframe.contentDocument || iframe.contentWindow.document;
      const fmDiv = iframeDoc.getElementById("fm");
      if (iframe) {
        if (iframeDoc) {
            if (fmDiv) {
              fmDiv.classList.remove("is-dark");
            }
        }
      }
    },
    saveColorsTheme() {
      this.changeColorsTheme();

      localStorage.setItem(
        "theme",
        JSON.stringify({
          colors: this.selectedColors,
          style: this.selectedStyle,
          lightLogo: this.lightLogoData,
          darkLogo: this.darkLogoData,
          favicon: this.faviconData,
        })
      );
      const isDarkMode = this.selectedStyle.darkMode; 
      localStorage.setItem('isDarkMode', isDarkMode ? 'true' : 'false');
      if (this.$store.state.home.admin) {
        Api.put(`/settings/userapp-branding`, {
          colors: this.selectedColors,
          style: this.selectedStyle,
          lightLogo: this.lightLogoData,
          darkLogo: this.darkLogoData,
          favicon: this.faviconData,
        });
      }
      this.$store.dispatch("addAlert", {
        success: true,
        successMessage: this.$t("notification.style.update.success"),
      });
    },
  },
  mounted: function () {
    this.$nextTick(() => {
      this.selectedStyle = this.$store.state.ThemeModule.style;
    });

    const savedTheme = localStorage.getItem('theme');
  
    if (savedTheme) {
      const theme = JSON.parse(savedTheme);
      const isDarkMode = theme.style.darkMode;
      localStorage.setItem('isDarkMode', isDarkMode ? 'true' : 'false');
    }
  },
  created() {
    this.$router.onReady(() => {
      if (this.$route.query.branding) {
        setTimeout(() => {
          this.open = true;
        }, 1000);
      }
    });
  },
};
</script>

<style scoped lang="scss">
.style-drawer {
  visibility: visible;
  overflow: visible !important;
  z-index: 1010;
  box-shadow: none !important;
  background: #fff;
  transition-property: transform, visibility, width, margin;

  &:not(.style-drawer--open):hover {
    margin-right: 16px;
  }

  &::before {
    content: "";
    position: absolute;
    width: 100%;
    min-height: 100%;
    z-index: -10;
    left: 0;
    top: 0;
    box-shadow: 0px 12px 32px 6px rgb(0 0 0 / 6%),
      0px 16px 72px 8px rgb(0 0 0 / 8%);
    opacity: 0;
    transition: opacity 0.24s ease;
  }

  &--open,
  &:hover {
    &::before {
      opacity: 1;
    }
  }

  &__content {
    padding: 32px;

    min-height: 100%;
  }

  &__items-container {
    min-height: 100%;
    .lighten-2 {
    }
  }

  &.v-navigation-drawer::v-deep {
    .v-navigation-drawer__content {
      overflow: visible !important;
      // overflow-y: auto !important;
      background: var(--v-panel-base);

      .style-drawer__scroll {
        overflow-y: auto !important;
        max-height: 100%;

        &::-webkit-scrollbar {
          width: 14px;
        }

        &::-webkit-scrollbar-track {
          box-shadow: none;
          background-color: transparent;
          max-width: 8px;
          border: 3px solid transparent;
        }

        &::-webkit-scrollbar-thumb {
          background-color: var(--v-gray-lighten2);
          border-radius: 8px;
          border: 4px solid transparent;
          background-clip: padding-box;
        }
      }
    }
  }

  .v-navigation-drawer__border {
    display: none;
  }

  &__activator {
    background: var(--v-panel-base);
    cursor: pointer;
    width: 98px;
    height: 98px;
    position: absolute;
    top: 208px;
    left: -96px;
    border-radius: 8px 0px 0px 8px;
    padding: 8px 20px 18px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: -1;
    box-shadow: $box-shadow-elevation-8;

    &-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    &-icon {
      min-height: 46px;
      width: 46px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &-title {
      color: map-get($text, headings);
      font-weight: $font-weight-semibold;
      line-height: 14px;
    }
  }
}
.v-application--is-rtl {
  .style-drawer {
    &__activator {
      left: unset;
      right: -96px;
      border-radius: 0px 8px 8px 0px;
    }
  }
  .v-navigation-drawer {
    &--right {
      left: 0;
      right: unset;
    }
    &--close {
      transform: translateX(-100%) !important;
    }
    &--open {
      transform: translateX(0%) !important;
    }
  }
}

.v-input--checkbox::v-deep {
  &.v-input--switch {
    > .v-input__control {
      display: none;
    }
    .v-input__slot {
      margin-bottom: 0;
      min-height: unset;
    }
    .v-input {
      &__append-outer {
        margin-left: 3px;
      }
    }
  }
}

p {
  color: var(--v-gray-darken1);
}
</style>