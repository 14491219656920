<template>
  <v-chip
    class="p-3 font-weight-regular"
    :class="[statusColor(status), { chipTable: small ? 'v-chip--table' : '' }]"
  >
    {{ value }}
  </v-chip>
</template>

<script>
export default {
  props: {
    value: String,
    status: {
      type: String,
      default: "Default",
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    statusColor: function (status) {
      if (status == "gray") {
        return `${status} lighten-5 headings--text`;
      } else if(status == "primary") {
        return `${status} lighten-5`;
      } else {
        return `${status} ${status}--text lighten-5 text--darken-1`;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.v-chip {
  &.chipTable {
    padding: 2px 8px;
    border-radius: 4px;
    max-height: 24px;
  }
}

.theme--dark {
  .gray{
    color: var(--v-gray-darken1);
    background: var(--v-gray-lighten5) !important;
  }
  .primary {
    color: var(--v-success-base);
    background-color: var(--v-success-lighten5) !important;
  }
  .purple {
    color: var(--v-role6-text) !important; 
    background-color: var(--v-role6-bg) !important;
  }
}

.theme--light {
  .purple {
    color: var(--v-role6-text) !important; 
    background-color: var(--v-role6-bg) !important;
  }
}


</style>